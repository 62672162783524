
.user-input-wrp {
    position: relative;
    width: 50%;
  }
  .user-input-wrp .inputText{
    width: 100%;
    outline: none;
    border:none;
    border-bottom: 1px solid #777;
     box-shadow: none !important;
  }
  .user-input-wrp .inputText:focus{
    border-color: blue;
    border-width: medium medium 2px;
  }
  .user-input-wrp .floating-label {
    position: absolute;
    pointer-events: none;
    top: 18px;
    left: 10px;
    transition: 0.2s ease all;
  }
  .user-input-wrp input:focus ~ .floating-label,
  .user-input-wrp input:not(:focus):valid ~ .floating-label{
    top: 0px;
    left: 10px;
    font-size: 13px;
    opacity: 1;
  }

  .button-list-mobile {
    font-size:12px; 
    /* width:100px;  */
    /* height:40px; */
    /* backgroundColor: 'lightgray'; */
    margin: 3px 3px 3px 3px;
    border-width:0; border-radius: 20px; 
}
  
.button-list {
    font-size:14px; 
    width:150px; 
    height:50px;
    /* backgroundColor: 'lightgray'; */
    margin: 15px 15px 15px 15px;
    border-width:0; border-radius: 20px; 
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px; /* Space around the container */
  }
  
  .custom-container {
    padding: 30px;
    width: 90%;
    max-width: 900px; /* Maximum width for large screens */
    background-color: #2c2c2e;
    border-radius: 6px;
  }
  
  .custom-row {
    padding-left: 10px;
  }
  
  @media (max-width: 768px) {
    .no-border {
      border: none !important;
    }
  }
  
  .custom-col {
    border-right: 0px solid #ffffff; /* Using border-md-end class in Bootstrap 5 */
  }
  
  .custom-muted {
    padding-bottom: 10px;
    font-size: 0.75rem;
    color: #ffffff;
  }
  
  .custom-heading {
    color: white;
  }
  
  .custom-button-div {
    padding-top: 30px;
    text-align: center;
  }
  
  .password-input-container {
    position: relative;
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }