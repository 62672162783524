.dropdown-item {
    color: white;
  }
  
  .dropdown-item:hover {
    background-color: #dddddd; /* Grey color on hover */
  }

  /* .btn {
    background: none;
    border: 2px solid var(--color);
    padding: 0.8em 1em;
    margin: 1em;
    user-select: none;
    text-transform: uppercase;
    color: var(--color);
    letter-spacing: 2.2px;
    cursor: pointer;
    border-radius: 10px;
    transform: translateY(0);
    transition: all 0.5s linear;
} */
.popup:hover {
  box-shadow: 0rem 0.99rem 0.43rem -0.33rem var(--color);
  transform: translateY(-0.5rem);
}