/* CustomInput.css */
.custom-input-container {
    position: relative;
    margin-bottom: 0.5rem;
}

.custom-input-label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(50%);
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    color: #ffffff;
}

.custom-input-field {
    border-radius: 0%;
    height: 60px;
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid #58b7aa;
    background-color: transparent;
    color: #58b7aa;    
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.custom-input-field::placeholder {
    color: #ffffff; /* or any color you prefer */
    opacity: 1; /* Firefox applies a lower opacity by default */
  }
  
  /* Optionally, you can also style the placeholder for different states like focus */
  .custom-input-field::placeholder {
    color: #ffffff; /* or any color you prefer */
  }

.custom-input-field:focus {
    border-color: rgba(235, 12, 12, 0)important;
}

.custom-input-field:focus + .custom-input-label,
.custom-input-field:not(:placeholder-shown) + .custom-input-label {
    transform: translateY(-22%) translateX(20%);
    font-size: 0.75rem;
    color: #58b7aa;
}
.custom-input-label {
    color: #ffffff;
}
.custom-input-error {
    color: #dc35469b;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.custom-input .form-control {
    border: none;
    border-bottom: 1px solid #58b7aa;
    border-radius: 0;
}

 .form-control:focus {
    /* color: black; */
    background-color: #ffffffae; 
    border-color: #58b7aa;
    box-shadow: none;
    
}

.form-control:disabled{
    
    background-color: #868686;
    opacity: 1;
}