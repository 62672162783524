.box {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .box-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .box-content {
    font-size: 16px;
    line-height: 1.5;
  }

  .month {
    font-weight: 600;
    font-size: 18px;
    margin: -142px -80px 77px -270px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
  }

  .offerPrice {
    font-weight: 600;
    font-size: 35px;
    margin: -121px -80px 77px 75px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
  }


  .planName {
    font-weight: 400;
    font-size: 16px;
    margin: -180px -371px 77px -250px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
  }

  .rupee {
    font-weight: 700;
    font-size: 25px;
    margin: -137px -365px 77px -270px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
}

.oldPrice {
    text-decoration: line-through;
    font-weight: 400;
    font-size: 25px;
    margin: -65px -252px 84px -269px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
}

.hover01 figure:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  .zoom-effect {
    transform: scale(1);
  }
  
  .image-container:hover .zoom-effect {
    transform: scale(1.1);
  }
  .dropdown-menu {
    --bs-dropdown-bg: black;
  }
  
  .form-select {
    background-color: black;
    color: #ffffff;
  }
  .modal {
    --bs-modal-bg: black;
    border-radius: 12px;
  }
  