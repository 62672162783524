.zoomed-card {
    transform: scale(0.9); /* Adjust the scale factor for the desired zoom level */
    transition: transform 0.3s ease; /* Adjust the transition duration and timing function as needed */
  }
.Profile {
    text-align: center;
  }
  
  .upper {
    padding: 20px;
  }
  
  .centeredImage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .circularImage {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-color: rgb(0, 0, 0);
    padding: 10px;
  }
  
  button {
    margin-top: 10px;
  }
  
  .lower {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .subPortion {
    padding: 30px;
    flex: 1;
    margin-bottom: 20px; /* Add margin between subPortions */
  }
  
  .line {
    width: 80%;
    margin: 0 auto;
    border: 1px solid rgb(121, 121, 121);
  }
  
  @media (min-width: 768px) {
    .lower {
      flex-direction: row;
    }
  
    .subPortion {
       /* Remove margin between subPortions */
    }
  
    .leftPortion {
        
      margin-right: 10px;
    }
  
    .rightPortion {
      margin-left: 10px;
    }
  
    .line-lower {
      margin-top: 0;
      margin-bottom: 0;
      height: auto;
      width: 1px;
      background-color: rgb(255, 255, 255);
    }
  }