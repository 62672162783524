.video-container {
    position: relative;
    max-width: 800px;
    margin: auto;
    background-color: black;
}

.video {
    width: 100%;
    display: block;
}

.controls {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

button {
    background-color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}

input[type="range"] {
    width: 100px;
}